import React from 'react';

import {
    IntroHolder,
    TwoColumns,
    Sidebar,
    NormalQuote,
} from '@bahaide/shared/components/InnerPage';

import InnerPage from '../../../../components/InnerPage';

const GemeinsamesLernenZitate = () => (
    <InnerPage title="Zitate – Gemeinsames Lernen" description="">
        <IntroHolder
            title="Zitate"
            subtitle="Gemeinsames Lernen"
            breadcrumbs={{
                grandparent: 'Woran Bahá’í glauben',
                parent: 'Die Natur des Menschen',
                'current-item': 'Zitate',
            }}
        />
        <TwoColumns>
            <div id="content">
                <NormalQuote reference="Bahá’u’lláh, Verborgene Worte, arabisch 68">
                    Und unter allen Geschöpfen hat Er den Menschen erwählt, um
                    ihm Seine wunderbarste Gabe zu schenken, und ihm ermöglicht
                    die Wohltaten der himmlischen Heerscharen zu erlangen. Diese
                    kostbareste Gabe ist Seiner unfehlbaren Führung teilhaftig
                    zu werden, damit das innerste Wesen der Menschheit gleichsam
                    zu einer Nische für diese Lampe werde; und wenn strahlende
                    Herrlichkeit ihres Lichtes auf den klaren Spiegel des
                    Herzens trifft, lässt die Reinheit des Herzens die Strahlen
                    noch heller als zuvor aufflammen und herrlich auf Geist und
                    Seele der Menschen scheinen. Von Erkenntnis und Weisheit und
                    vom Wissen um die Mysterien der heiligen Worte hängt es ab,
                    ob die höchste Führung erlangt wird. Daher müssen die
                    Geliebten Gottes, jung und alt, Mann und Frau, jeder nach
                    seinen Fähigkeiten, bestrebt sein die verschiedenen
                    Wissensgebiete zu erforschen, ihr Verständnis der
                    Geheimnisse der heiligen Bücher zu vertiefen und ihre
                    Fertigkeit zu steigern, die göttlichen Beweise und Zeugnisse
                    anzuführen.
                </NormalQuote>
            </div>
            <Sidebar />
        </TwoColumns>
    </InnerPage>
);

export default GemeinsamesLernenZitate;
